import React, { useState, useEffect } from 'react';

export const AccordionFAQ = ({
  items, trigger
}) => {
const [isExpanded, setIsExpanded] = useState({});
const [isVisible, setIsVisible] = useState(false);
const toggleExpand = index => {
  setIsVisible(false)
  setIsExpanded(prevState => {
    const isCurrentlyOpen = !!prevState[index]; 
    const isOpening = !isCurrentlyOpen;
    setTimeout(() => {
      setIsVisible(isOpening);      
    }, 200);
    return {[index]: !prevState[index]};
  });
};
const isAnyItemExpanded = () => {
  return Object.values(isExpanded).some(status => status);
};
useEffect(() => {
  if (isAnyItemExpanded()) {
    setTimeout(() => {     
      trigger(true)
    }, 200);
  } else {
     setTimeout(() => {     
       trigger(false)
    }, 200);
  }
}, [isExpanded]);

  return (
    <>
    <ul className='max-w-screen-xl mx-auto flex flex-col px-3 md:px-8 space-y-2 md:space-y-1 w-full py-10'>
  {items.map((item, index) => (
    <li key={index} className={`${isExpanded[index] ? "bg-blue-100" : "bg-gray-200"} flex flex-col space-y-3 px-4 md:px-7 text-xs`}>
      <p className='flex justify-between items-center w-full font-bold pt-3 pb-0 md:pt-4 md:pb-1'>
       
        <div className={`flex-grow px-2 text-lg font-semibold font-display max-w-screen-xl`}>{item.title}</div>
        <div
          className={`bg-white p-2 rounded-full font-extrabold text-lg cursor-pointer text-center ${isExpanded[index] ? "bg-gray-500":"bg-blue-300" }`}
          onClick={() => toggleExpand(index)}
        >
         {isExpanded[index] ? 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className='rounded-full h-5 w-5 cursor-pointer'>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 12h12" />
          </svg> 
        : 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffff" className=' rounded-full h-5 w-5 cursor-pointer '>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
      }
        </div>
      </p>
      {isExpanded[index] && <div
        style={{
          maxHeight: isExpanded[index] && isVisible ? '1000px' : '0',
          paddingTop: isExpanded[index] && isVisible ? '10px' : '0',
          transition: 'max-height 0.3s ease-in-out',
          overflow: 'hidden'
        }}
      >
        {item.description}
      </div>}
    </li>
  ))}
</ul>
    </>
  )
}