import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import {faqs} from '../data/faqs'
import { GatsbyImage } from 'gatsby-plugin-image'
import { AccordionFAQ } from '../components/Accordian/AccordionFAQ'
import '../components/PageBannerImage/index.css'
import { Link } from 'gatsby'

const Faqs = (props) => {
	const [expanded, setExpanded] = useState(false);
	const getObjectPosition = () => {
		if (typeof window !== 'undefined' && window.innerWidth >= 1024) {
			return ['center'];
		} else {
			return ['right'];
		}
	};
	return (
		<Layout className="BlogPage">
			<Helmet>
				<title>Mineral Swim - Faqs</title>
				<meta
					name="description"
					content="Explore frequently asked questions on mineral pool, magnesium pool and pool maintenance in general."
				/>
				<meta name="og:site_name" content="faqs" />
				<meta name="keywords" content="faqs" />
				<link rel="canonical" href="https://mineralswim.com/faqs/" />
			</Helmet>
			<div className="fixed w-screen h-screen" style={{}} data-scroll
      data-scroll-speed="-10">
				<GatsbyImage
							image={props.data.faqBannerGatsby.nodes[0].gatsbyImageData}
							className="w-screen h-full lg:object-center object-right object-cover"
							loading="eager"
							alt="Test"
							objectPosition={getObjectPosition()}
						/>
				</div>
			<main className=""  style={{ position: 'relative', zIndex: 2,}}>
			<section className='w-screen flex flex-col justify-start' style={{height:"72vh"}}>		
					<header className='main-page-header bg-fixed bg-center lg:bg-left' style={{
                // backgroundImage: `url(${FAQBanner})`,
            }}>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'  style={{ position: 'relative', zIndex: 2 }}>
								<div className='hidden lg:flex col-span-6'></div>
								
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
									<div className='bg-white bg-opacity-60 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Everything You Need to Know About Mineral Swim<span className='text-lg md:text-2xl align-text-top'>&trade;</span></h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>			
									</div>
									<p className='text-black md:text-base leading-6'>Welcome to the Mineral Swim™ Frequently Asked Questions page, where you can find answers to the most common questions about Mineral Swim™.  
										</p>				
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
										<Link to='#faqs'><p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
										</Link>						
									</div>								
									</div>
									</div>                
							</div>
								</header>
								</section>
								<section className='w-screen bg-white'>
								<p  id='categories'  className="py-6 max-w-screen-xl mx-auto px-5 w-full text-center font-display font-medium text-lg md:text-2xl">
								Discover the benefits of Mineral Swim™, installation tips, maintenance advice and more. If you can’t find the answers you need, contact our expert customer team at <a href="tel:1300693657">1300 693 657</a> or <a href="mailto:info@mineralswim.com">info@mineralswim.com</a>.
				</p>
								</section>
								<section id="faqs" className={`-mt-10 w-screen bg-white ${ expanded ? 'lg:pb-0': 'lg:pb-36'}`} style={{transition: 'padding-bottom 0.3s ease-in-out',}}>
                <AccordionFAQ  items={faqs} trigger={setExpanded} />
								</section>
			</main>
		</Layout>
	)
}

export const FaqsPageQuery = graphql`
	query faqDataQueryAU {
			faqBannerGatsby: allContentfulAsset(
			filter: {
				contentful_id: { eq: "1ErNeLgjj6dQHUGUGMu7c0" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description

				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
	}
`


export default Faqs
