import React from 'react'


 export const faqs = [
    {
      title:"What is a Mineral swimming pool?",   
      description:
      <>
      <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
      A mineral swimming pool is a gentler, more sophisticated alternative to traditional chlorinated or salt pools. Mineral pools cleanse and restore the body while you unwind and swim in the pool. When compared to their synthetic chlorine counterpart, they use natural minerals to achieve comparable or better water quality. Mineral Swim™ pools are 100% Dead Sea minerals – rich in magnesium and combine with Ozone Purification for a more powerful pool sanitiser.
      </p>
      </>
    },
    {
      title:"What is the difference between a Mineral Pool and a conventional pool?",  
      description:
      <>
     <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                Chlorine pools: 
                <ul>✔️ Are the least expensive to install.</ul>	
	            <ul>✔️ The maintenance of a chemical system is very time consuming.</ul>
            	<ul>✔️ Pool chlorine can be rough on sensitive skin.</ul>
                Mineral pools:
                <ul>✔️ Are slightly more expensive to install than chlorine pools.</ul>
                <ul>✔️ Require roughly the same level of upkeep as chlorine.</ul>
                <ul>✔️ Have minimal recurring electricity expenses because their eco-pumps run for half the period of a chlorine pump.</ul>
                <ul>✔️ Almost no one is allergic to magnesium, and it is extremely beneficial to the skin and body.</ul>	
                </p>
      </>
    },
    {
      title:"What is the difference between a Mineral Pool and a saltwater pool? ",  
      description:
      <>
      <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                A saltwater pool will last longer than a mineral swimming pool. The pool chlorine will degrade over time, and with proper management, it can last anywhere from five to ten years before you need to refill your swimming pool. A mineral pool must be tested on a regular basis because allowing the minerals to decrease risks bacteria build-up and algae growth. However, don't let this stop you. A mineral pool system is easier to balance and provides much more cost-effective pool sanitation solutions.
                </p>
      </>
    },
    {
      title:"What are the benefits of a Mineral Pool?",  
      description:
      <>
      <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                Mineral pools bring an abundance of natural health benefits, including improved skin texture and elasticity, boosted energy levels, improved blood circulation, muscle relaxation and enhanced sleeping patterns. It has also been proven to penetrate and repair your skin as you swim and even when you’re out of the pool! That is why dermatologists around the world agree on the healing effects of magnesium on sensitive skin and its ability to soothe common skin conditions such as dermatitis, eczema, and psoriasis! Read more on the <a className='underline' href='https://mineralswim.com/mineral-pool-system/' target='blank'>Advantages of Mineral Pool</a>!
                </p>
      </>
    },
    {
      title:"How do I maintain a Mineral Pool?",  
      description:
      <>
      <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                Looking after a Mineral Swim&trade; pool is easier than most may think. Here are a few hassle-free pool care guidelines to help you keep your mineral pool water healthy and crystal clear: 
                <ul>✔️ Don’t skip the basics: Skim the surface, clean your filters, brush the walls and sweep your pool regularly.</ul>	
	            <ul>✔️ Get the right cleaning equipment: Telescopic pole, skimmer net or leaf shovel or leaf scoop, and vacuum.</ul>
            	<ul>✔️ Keep your swimming pool chemical levels within the recommended parameters (follow your manufacturer's instructions).</ul>
                <ul>✔️ Set a schedule: As a general rule of thumb, cleaning your swimming pool should be part of a weekly routine.</ul>
                Read further on <a className='underline' href='https://mineralswim.com/how-to-look-after-a-mineral-swim-pool/' target='blank'>How to maintain Mineral Swimming Pool</a>.
                </p>
      </>
    },
    {
      title:"How do Mineral Pool Systems work in general?",  
      description:
      <>
      <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                Mineral Pool systems use mineral salts that produce low doses of chlorine when interacting with a chlorinator. Lower doses of pool chlorine mean it’s gentler on the skin and smells better.  Maytronics Mineral Pool systems combine the use of dead sea salt and ozone purification which is up to 3,000 times more powerful than ordinary water purification systems reducing pool chlorine requirement by up to 80%. Ozone instantly neutralises any organic contaminants and convert them into oxygen before the water hits your pool. On top of that, mineral-rich water promotes better health, beauty and wellbeing. 
                </p>
      </>
    },
    {
      title:"Are Mineral Pools and Magnesium Pools the same thing?",  
      description:
      <>
     <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                Magnesium pools are also known as Mineral pools as they use the mineral magnesium chloride combined with chlorine, to sanitise your pool. There are different types of mineral pools which use different minerals or a combination of minerals. In a Mineral Swim&trade; pool, the prime mineral is magnesium. It is recognised that a magnesium rich mineral pool is one of the best options for pool owners. 
                </p>
      </>
    },
    {
      title:"Does Mineral Swim™ provide swimming pool safety inspections?",  
      description:
      <>
     <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                No, pool safety inspections are to ensure your pool is compliant with your states pool safety regulations. Pool safety inspections measure, test and take photos of your pool gates, pool fences, dividing fences used as pool fences, and objects or structures near the pool fence to see if you achieve pool safety compliance. Pool safety inspections do not look at the pool equipment or sanitisation methods used in your pool, so therefore Mineral Swim™ would not be a part of a safety inspection. 
                <br/>
                When purchasing a Mineral Swim™ system – and as part of the customer care plan - the Maytronics authorised dealer will do a complimentary health check and service of your pool six weeks after the new installation or conversion. If you have access to the Maytronics for Life program then you will be offered a yearly pool health check, otherwise it is up to the swimming pool owner to maintain their own pool and organise and relevant safety inspections. 
                </p>
      </>
    },
    {
      title:"How effective is ozone purification sanitising?",  
      description:
      <>
     <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                Ozone is nature’s most powerful sanitiser. Ozone is a strong oxidiser which can kill microorganisms and viruses effectively. It is one of the best oxidisers and instantly neutralises microorganisms such as viruses and bacteria and converts them to oxygen. As well as disinfecting water it also oxidises metals like iron, copper and manganese. It is more effective than chemical disinfectants such as chlorine. It is up to 3,000 times more effective than other water purification systems and can help reduce pool chlorine needs by up to 80%.   
                </p>
      </>
    },
    {
      title:"What is the difference between the Mineral Swim™ PRO and Mineral Swim™ Purifier? ",  
      description:
      <>
     <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
     Mineral Swim™ PRO combines ozone purification with mineral chlorination and Dead Sea Minerals, eliminating the need for a separate chlorinator and simplifying installation and operation. This makes it ideal for new pools or upgrading existing equipment. The Mineral Swim™ Purifier provides natural ozone purification and is suitable for use with an existing chlorinator.  
                </p>
      </>
    },
    {
      title:"What minerals are found in Maytronics Mineral Swim™ pools? ",  
      description:
      <>
                <p className="pb-10 px-2 w-full  font-display font-medium text-sm max-w-screen-xl mx-auto">
                The types of minerals that could be found in a Mineral Swim™ swimming pool are: 
                <ul className='mt-4'>✔️ 100% Dead Sea Minerals, magnesium-rich minerals sustainably extracted from Israel’s Dead Sea. </ul>	
	            <ul>✔️ Magnesium minerals sourced from the Dead Sea greater than 60%</ul>
            	<ul>✔️ Other (chloride) minerals sourced from the Dead Sea 30-60% </ul>
                <ul>✔️ Other non-hazardous ingredients sourced from the Dead Sea less than 10% </ul>	
                </p>
      </>
    },
  ]